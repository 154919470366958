import React from "react"
import { getSrc } from "gatsby-plugin-image"
import GGFXTransform from "../components/common/stb-jamstack-ggfxclient/module/components/ggfx-transform";
import NoImage from "../images/no-image.png"

//var strapi_url = "https://strettons-strapi.q.starberry.com";
 var strapi_url = process.env.GATSBY_STRAPI_SRC;

export const ImageModule = (props) => {
        if(props.ImageSrc && props.ImageSrc.url){

        var image_url = props.ImageSrc.url;
        if(props.ImageSrc.url_sharp){
            image_url = getSrc(props.ImageSrc.url_sharp)
        }

        let alt_text = props.ImageSrc.alternativeText ? props.ImageSrc.alternativeText : props.altText;
        let renderer = props.renderer ? props.renderer :"srcSet";
        return(
            <>
                {
                    props.imagename ? (<>
                      <GGFXTransform mediaUrl={props.ImageSrc.url} altText={alt_text} renderer={renderer} 
                    transformConfigName={props.imagename} 
                    preResult={props?.ggfx_results} id={props.strapi_id} /> </>
                    ) : (
                        <img className={props.classNames} src={image_url} alt={alt_text} title={props.title} loading="lazy" />
                    )
                }
                
            </>
        )
    } else{
        return <img src={NoImage} alt="img" />;
    }
}
