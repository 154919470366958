import React from "react"
import { isEmpty, find } from "lodash"
import { ImageTransform } from "./transform/image"

const MediaTypesAndFormats = [[["png", "jpeg", "jpg", "webp", "svg"], "image"]]

// check the media format from url
// eslint-disable-next-line consistent-return
const FindFormat = (url) => {
  try {
    const MediaFormat = url.substring(url.lastIndexOf(".") + 1)
    // eslint-disable-next-line func-names
    const FoundedMediaType = find(MediaTypesAndFormats, function (type) {
      return type[0].includes(MediaFormat.toLowerCase())
    })
    return isEmpty(FoundedMediaType)
      ? null
      : { format: MediaFormat, type: FoundedMediaType[1] }
  } catch (error) {
    console.log("FindFormat error", error)
    return ""
  }
}

// eslint-disable-next-line consistent-return
const GGFXTransform = (props) => {
  if (isEmpty(props)) {
    console.log("ImageTransform is empty!")
    return ""
  }
  // eslint-disable-next-line react/destructuring-assignment
  if (isEmpty(props.mediaUrl)) {
    console.log("ImageTransform source url or image name is empty!")
    return ""
  }
  // eslint-disable-next-line react/destructuring-assignment
  const { format, type } = FindFormat(props.mediaUrl)
  if (!type || type === "image") {
    // console.log('format, type', format, type)
    // eslint-disable-next-line react/react-in-jsx-scope
    return <ImageTransform {...props} mediaFormat={format} />
  }
  return <h3>GGFXTransform Not Applied For {format}</h3>
}

export default GGFXTransform
